var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"elevation-1"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-container',{staticClass:"mx-0"},[_vm._l((_vm.selectProjectConfig),function(type){return _c('v-row',{key:`select-project-${type.id}`},[_c('v-col',{attrs:{"cols":"6","lg":"5","md":"6"}},[_c('v-autocomplete',_vm._b({attrs:{"search-input":_vm.search[`projects${type.id}`]},on:{"update:searchInput":function($event){return _vm.$set(_vm.search, `projects${type.id}`, $event)},"update:search-input":function($event){return _vm.$set(_vm.search, `projects${type.id}`, $event)}},scopedSlots:_vm._u([_vm._l((['selection', 'item']),function(name){return {key:name,fn:function({item, index}){return [(type.id === 'SW' && name === 'selection')?_c('v-chip',{key:type + name + index,staticClass:"ma-1",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeProjectFromSelection(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")]):_c('div',{key:type + name,staticClass:"d-flex align-center"},[_c('img',{attrs:{"width":"16","src":'https://favicon.yandex.net/favicon/v2/' + item.host.name,"alt":item.label}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.label)+" "),(name === 'item')?_c('sup',[_vm._v(_vm._s(item.id))]):_vm._e()])])]}}})],null,true),model:{value:(_vm.$v.form[type.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.form[type.name], "$model", $$v)},expression:"$v.form[type.name].$model"}},'v-autocomplete',{
								label: type.label,
								placeholder: type.placeholder,
								items: type.items,
								outlined: true,
								dense: true,
								loading: _vm.loading[`projects${type.id}`],
								itemText: 'label',
								itemValue: 'id',
								clearable: true,
								errorMessages: type.errorMessages,
								multiple: type.multiple,
								disabled: type.disabled
							},false))],1)],1)}),_vm._l((_vm.textFieldConfig),function(type,i){return _c('v-row',{key:`field-${i}`},[_c('v-col',{attrs:{"cols":"6","lg":"5","md":"6"}},[_c('v-text-field',_vm._b({model:{value:(_vm.$v.form[type.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.form[type.name], "$model", $$v)},expression:"$v.form[type.name].$model"}},'v-text-field',{
								label: type.label,
								outlined: true,
								dense: true,
								placeholder: 'Введите идентификатор',
								errorMessages: _vm.getError(type.name),
								type: 'number',
								disabled: !_vm.form.project_id,
								clearable: true
							},false))],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"5","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }