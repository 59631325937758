import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { NOTIFY_STATUS } from '@/const'

const ERROR_TEXT_REQUIRED = 'Поле обязательно'

export default {
	name: 'projectIntegration',

	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'DF | Интеграция проектов',
			form: {
				project_id: undefined,
				seowork_project_ids: [],
				google_analytics_profile_id: undefined,
				google_analytics_ecom_profile_id: undefined
			},
			loading: {
				projectsDF: false,
				projectsSW: false
			},
			search: {
				projectsDF: null,
				projectsSW: null
			},
			timeOutLoad: {
				projectsDF: undefined,
				projectsSW: undefined
			}
		}
	},

	validations: {
		form: {
			project_id: { required },
			seowork_project_ids: {
				required: val => !!val.length
			},
			google_analytics_profile_id: { required },
			google_analytics_ecom_profile_id: { required }
		}
	},

	computed: {
		...mapState({
			projectsDF: state => state.projectIntegration.projectsDF,
			projectsSW: state => state.projectIntegration.projectsSW
		}),

		selectProjectConfig () {
			return [
				{
					id: 'DF',
					name: 'project_id',
					label: 'Проект DF',
					placeholder: 'Выберите проект',
					items: this.projectsDF.items,
					errorMessages: this.getError('project_id')
				},
				{
					id: 'SW',
					name: 'seowork_project_ids',
					label: 'Проекты SW',
					placeholder: 'Выберите проекты',
					items: this.projectsSW.items,
					multiple: true,
					errorMessages: this.$v.form.seowork_project_ids.$dirty && !this.$v.form.seowork_project_ids.required ? ERROR_TEXT_REQUIRED : '',
					disabled: !this.form.project_id
				}
			]
		},

		textFieldConfig () {
			return [
				{
					name: 'google_analytics_profile_id',
					label: 'Идентификатор профиля аналитики'
				},
				{
					name: 'google_analytics_ecom_profile_id',
					label: 'Идентификатор профиля ecomm аналитики'
				}
			]
		}
	},

	watch: {
		async 'search.projectsDF' (val) {
			await this.searchProjects({
				type: 'DF',
				q: val
			})
		},
		async 'search.projectsSW' (val) {
			await this.searchProjects({
				type: 'SW',
				q: val
			})
		},
		async 'form.project_id' (val) {
			try {
				if (val) {
					await this.getProjectSettings({
						query: {
							project_id: val,
							skip_regionality: 1
						}
					})
						.then(result => {
							if (result && Array.isArray(result) && result.length) {
								const data = result[0]
								this.$set(this.form, 'seowork_project_ids', data.seowork_project_ids)
								this.$set(this.form, 'google_analytics_profile_id', data.google_analytics_profile_id)
								this.$set(this.form, 'google_analytics_ecom_profile_id', data.google_analytics_ecom_profile_id)

								const projectNeedsData = data.seowork_project_ids.filter(id => !this.projectsSW.items.find(project => project.id === id))
								if (projectNeedsData.length) {
									this.getProjectList({
										type: 'SW',
										project_id: projectNeedsData
									})
								}
							}
						})
						.catch(({ message }) => {
							this.$notify({ type: NOTIFY_STATUS.ERROR, title: message })
							throw new Error()
						})
				} else {
					throw new Error()
				}
			} catch (_) {
				this.$set(this.form, 'seowork_project_ids', [])
				this.$set(this.form, 'google_analytics_profile_id', undefined)
				this.$set(this.form, 'google_analytics_ecom_profile_id', undefined)
			}
		}
	},

	created () {
		this.getProjectList({ type: 'DF' })
		this.getProjectList({ type: 'SW' })
	},

	methods: {
		...mapActions({
			getProjects: 'projectIntegration/getProjects',
			getProjectSettings: 'projectIntegration/getProjectSettings',
			setProjectSettings: 'projectIntegration/setProjectSettings'
		}),

		async getProjectList (payload) {
			this.$set(this.loading, `projects${payload.type}`, true)
			try {
				await this.getProjects(payload)
			} catch ({ message }) {
				this.$notify({ type: NOTIFY_STATUS.ERROR, title: message })
			} finally {
				this.$set(this.loading, `projects${payload.type}`, false)
			}
		},

		async searchProjects (payload) {
			if (this.timeOutLoad[`projects${payload.type}`]) {
				clearTimeout(this.timeOutLoad[`projects${payload.type}`])
			}

			this.$set(this.timeOutLoad, `projects${payload.type}`, setTimeout(async () => {
				await this.getProjectList(payload)
			}, 1000))
		},

		removeProjectFromSelection (item) {
			const index = this.form.seowork_project_ids.indexOf(item.id)
			const items = [...this.form.seowork_project_ids]
			if (index >= 0) {
				items.splice(index, 1)
				this.$set(this.form, 'seowork_project_ids', items)
			}
		},

		getError (name) {
			if (this.$v.form[name].$dirty && !this.$v.form[name].required) {
				return ERROR_TEXT_REQUIRED
			}
			return ''
		},

		async submit () {
			this.$v.$touch()

			if (this.$v.$invalid) {
				return
			}

			try {
				await this.setProjectSettings({
					query: {
						project_id: this.form.project_id
					},
					body: {
						seowork_project_ids: this.form.seowork_project_ids,
						google_analytics_profile_id: this.form.google_analytics_profile_id,
						google_analytics_ecom_profile_id: this.form.google_analytics_ecom_profile_id
					}
				})
					.then(() => {
						this.$notify({ type: NOTIFY_STATUS.SUCCESS, title: 'Настройки сохранены' })
					})
			} catch ({ message }) {
				this.$notify({ type: NOTIFY_STATUS.ERROR, title: message })
			}
		}
	}
}
